var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useCallback, useMemo, } from 'react';
import { SnackbarContent } from 'notistack';
import styled from '@emotion/styled';
import { ReactComponent as CloseIcon } from '@icons/wolfkit-light/xmark-light.svg';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import { BodyMedium } from '@components/styled/Typography';
import useToastNotification from './useToastNotification';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon, } from './icons';
const TOAST_STYLES = {
    success: {
        light: {
            backgroundColor: '#ECFBF1',
            borderBottom: '4px solid #D6F2DF',
            color: '#080A0B',
            closeBtnColor: '#30A46C',
        },
        solid: {
            backgroundColor: '#30A46C',
            borderBottom: 'unset',
            color: '#fff',
            closeBtnColor: '#fff',
        },
    },
    warning: {
        light: {
            backgroundColor: '#FFF9ED',
            borderBottom: '4px solid #FFECBC',
            color: '#4E2009',
            closeBtnColor: '#EE9D2B',
        },
        solid: {
            backgroundColor: '#FFA01C',
            borderBottom: 'unset',
            color: '#fff',
            closeBtnColor: '#fff',
        },
    },
    error: {
        light: {
            backgroundColor: '#FFF8F8',
            borderBottom: '4px solid #FFE5E5',
            color: '#381316',
            closeBtnColor: '#EB9091',
        },
        solid: {
            backgroundColor: '#D4393E',
            borderBottom: 'unset',
            color: '#fff',
            closeBtnColor: '#fff',
        },
    },
    info: {
        light: {
            backgroundColor: '#F8FAFF',
            borderBottom: '4px solid #E6EDFE',
            color: '#080A0B',
            closeBtnColor: '#6488F0',
        },
        solid: {
            backgroundColor: '#6488F0',
            borderBottom: 'unset',
            color: '#fff',
            closeBtnColor: '#fff',
        },
    },
};
const ToastContainer = styled(SnackbarContent)(props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: props.theme.spacing_sizes.s,
    padding: props.displayStyle === 'solid' ?
        `${props.theme.spacing_sizes.xs * 2.5}px ${props.theme.spacing_sizes.xs * 4}px` :
        `${props.theme.spacing_sizes.xs * 2.5}px ${props.theme.spacing_sizes.xs * 4}px ${props.theme.spacing_sizes.xs * 2}px`,
    backgroundColor: TOAST_STYLES[props.variant][props.displayStyle].backgroundColor,
    borderBottom: TOAST_STYLES[props.variant][props.displayStyle].borderBottom,
    color: TOAST_STYLES[props.variant][props.displayStyle].color,
}));
const ToastInner = styled.div(props => ({
    display: 'flex',
    flexDirection: 'row',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'center',
}));
const ToastText = styled(BodyMedium)();
const CloseButton = styled(ButtonCustom)(props => ({
    minWidth: 'unset',
    minHeight: 'unset',
    border: 'none',
    transition: 'none',
    padding: 4,
    background: 'transparent',
    '&:hover': {
        border: 'none',
        background: 'transparent',
    },
    svg: {
        width: 16,
        height: 16,
    },
    path: {
        fill: TOAST_STYLES[props.toastVariant][props.displayStyle].closeBtnColor,
    },
}));
const ToastComponent = ((_a, ref) => {
    var { id, variant, displayStyle = 'solid', className, message } = _a, props = __rest(_a, ["id", "variant", "displayStyle", "className", "message"]);
    const { closeToast } = useToastNotification();
    const handleClose = useCallback(() => {
        if (id) {
            closeToast(id);
        }
    }, [id, closeToast]);
    const StatusIcon = useMemo(() => {
        switch (variant) {
            case 'success':
                return _jsx(SuccessIcon, { displayStyle: displayStyle });
            case 'warning':
                return _jsx(WarningIcon, { displayStyle: displayStyle });
            case 'error':
                return _jsx(ErrorIcon, { displayStyle: displayStyle });
            case 'info':
                return _jsx(InfoIcon, { displayStyle: displayStyle });
            default:
                return null;
        }
    }, [displayStyle, variant]);
    return (_createElement(ToastContainer, Object.assign({}, props, { variant: variant, displayStyle: displayStyle, ref: ref, key: id, className: className }),
        _jsxs(ToastInner, { children: [StatusIcon, _jsx(ToastText, { children: message })] }),
        _jsx(CloseButton, { variant: 'outlined', toastVariant: variant, displayStyle: displayStyle, onClick: handleClose, children: _jsx(CloseIcon, {}) })));
});
const Toast = forwardRef(ToastComponent);
export default Toast;
